import React from 'react';
import './Footer.css';
import logoKetalSA from '../../assets/image/logoKetalSA.png';

const Footer = () => {
    return (<footer>
        <div className="footer-container">
            <div className="footer-container-section-1">
                <div className="footer-contact-section">
                    <p className="footer-contact-section-title">
                        CONTÁCTANOS:</p>
                    <p>Email: contacto@ketal.com.bo</p>
                    <p>Av. Rafael Pabón, Megacenter, Irpavi</p>
                    <p>Telefóno: 2 2155555 - 2 2155556</p>
                </div>
                <div className="footer-whoweare-section">
                    <p className="footer-contact-section-title">
                        ¿QUIÉNES SOMOS?</p>
                </div>
                <div className="footer-panels-section">
                    <div className="footer-panels-section-column">
                        <a href="http://catalogos.ketal.com.bo/AJ_mama_ketal.pdf" target="_blank">
                            <img src={require('../../assets/image/Banner_AJ_mama_ketal.png')}
                                alt="Banner_AJ_mama_ketal" />
                        </a>
                    </div>
                    <div className="footer-panels-section-column">
                        <a href="http://catalogos.ketal.com.bo/RESOL-KETAL-ESFERAS.pdf" target="_blank">
                            <img src={require('../../img/Banner-navidad23.png')}
                                alt="Banner" />
                        </a>
                    </div>
                    <div className="footer-panels-section-column">
                        <a href="http://catalogos.ketal.com.bo/RESOL-KETAL-COMPRAS.pdf" target="_blank">
                            <img src={require('../../img/Banner-compras.png')}
                                alt="Banner-compras" />
                        </a>
                    </div>
                    <div className="footer-panels-section-column">
                        <a href="http://catalogos.ketal.com.bo/RESOL-KETAL-COMPRA-2FASE.pdf" target="_blank">
                            <img src={require('../../img/web-ruleta.chico.png')}
                                alt="web-ruleta.chico" />
                        </a>
                    </div>

                </div>
            </div>
            <div className="footer-container-section-2">
                <img src={logoKetalSA} className="footer-container-section-2-icon"/>
            </div>
        </div>
    </footer>);
}

export default Footer;